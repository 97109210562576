import React, {Component} from 'react';
import {webviewCall} from "../utils/webview";
import {hitEvent, hits} from "../utils/log";
import AppContext from "../contexts/AppContext";

export default class FileChooseButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      image: null,
    };

    this.fileFieldRef = React.createRef();

    this.handleBrowserFilesSelected = this.handleBrowserFilesSelected.bind(this);
    this.handleWebviewFilesSelected = this.handleWebviewFilesSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleError = this.handleError.bind(this);
    this.triggerSelectFile = this.triggerSelectFile.bind(this);
    this.logFileSelected = this.logFileSelected.bind(this);
  }

  componentDidMount() {
    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFilesSelected);
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
  }

  triggerSelectFile() {
    if (window.clientConfig.isWebview) {
      const params = {
        func: "onNativeAppPhotoSelected",
        use_crop: 0,
        num_photos: this.props.numPhotos || 1,
        show: "gallery",
        tab: "faces",
        neurocamera: 1,
      };

      webviewCall("nativePhotoSelect", params);
    } else {
      if (this.fileFieldRef.current) {
        this.fileFieldRef.current.value = "";
        this.fileFieldRef.current.click();
      }
    }
  }

  handleError(code, message) {
    this.props.onError && this.props.onError(code, message);
  }

  logFileSelected() {
    hitEvent(hits.PHOTO_SELECT);
  }

  handleBrowserFilesSelected(files) {
    this.logFileSelected();
    this.props.onFilesSelected && this.props.onFilesSelected(files);
  }

  handleWebviewFilesSelected(data) {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    this.logFileSelected();

    const filesUrls = data.photos.map((item) => {
      return item.image_url.replace("http://", "https://")
    });

    this.props.onFilesSelected && this.props.onFilesSelected(filesUrls);
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
    this.triggerSelectFile();
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <React.Fragment>
      <button
        disabled={this.props.disabled}
        className={this.props.className}
        onClick={this.handleClick.bind(this)}>
        {this.props.children}
      </button>

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        multiple
        ref={this.fileFieldRef}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => this.handleBrowserFilesSelected(e.target.files)} />
    </React.Fragment>;
  }
}

FileChooseButton.contextType = AppContext;