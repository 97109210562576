import React from "react";
import {hitEvent, hits, logCreativeResult, logEvent, userEvents} from "../utils/log";
import * as webviewUtils from "../utils/webview";
import PropTypes from "prop-types";
import AppContext from "../contexts/AppContext";
import WatermarkBuilderModal from "./watermark-builder/WatermarkBuilderModal";
import {
  creativeName,
  transformToDownloadUrl
} from "../utils/creative";
import {saveAs} from "file-saver";
import i18n from "../i18n";
import {assetUrl} from "../utils/etc";
import processingManager from "../photolab/ProcessingManager";
import Creative from "../photolab/Creative";
import {webviewAnalyticsEvent} from "../utils/webview";
import LazyImage from "../components/LazyImage";
import clientStorage from "../utils/client-storage";

export default class CreativeView extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.creative.id !== this.props.creative.id) {
      this._creativeElementClicks = 0;
    }
  }

  handleTouchStart = () => {
    // clearTimeout(this.touchTimer);
    // this.touchTimer = setTimeout(this.handleLongClick, 500);
  }

  handleTouchEnd = () => {
    // clearTimeout(this.touchTimer);
  }

  handleLongClick = () => {
    this._creativeElementClicks = (this._creativeElementClicks || 0) + 1;
    if (this._creativeElementClicks >= 2) {
      this._creativeElementClicks = 0;
      this.context.showToast({
        message: this.props.creative.templateId,
        delay: 2000,
      });
    }
  };

  handleClick = (e) => {
    console.log(`${this.props.creative.group}/${this.props.creative.templateId}`);

    if (this.props.isOverlayed) {
      return;
    }

    if (this.props.creative.isProcessed) {
      this.handleDownloadButtonClick(e);
    }
  };

  startDownload = (imageUrl, action = "download") => {
    webviewAnalyticsEvent("save_and_share", [
      "",
      "result",
      clientStorage.getProcessedPhotosAmount(),
      processingManager.processing.getExtra("batches_amount", 0),
      "download",
      this.props.position,
    ], {
      wt_couple_ai: {
        "face": this.props.creative.getExtra("t_face_id"),
        "gender": this.props.creative.getExtra("t_gender"),
        "canvas": this.props.creative.getExtra("t_canvas_id"),
        "mask": this.props.creative.getExtra("t_mask_id"),
        "tpl": this.props.creative.getExtra("t_prompt_id"),
      },
    });

    hitEvent(hits.DOWNLOAD);
    logEvent(userEvents.DOWNLOAD, {
      group: this.props.creative.group,
      template_id: this.props.creative.templateId,
      position: this.props.position,
    });

    logCreativeResult(
      creativeName(this.props.creative),
      processingManager.processing.files.map((f) => ({url: f.url})),
      [{url: this.props.creative.result}],
      true
    );

    if (action === "share") {
      this.props.shareHandler(imageUrl);
    } else {
      if (window.clientConfig.isWebview) {
        this.props.downloadHandler(imageUrl);
      } else {
        this.props.downloadHandler(transformToDownloadUrl(imageUrl));
      }
    }
  };

  handleDownloadButtonClick = (e) => {
    e.stopPropagation();

    if (window.clientConfig.features.downloadWithWatermarkBuilder) {
      this.context.pushModal(<WatermarkBuilderModal
        key="CreativeView_WatermarkBuilderModal"
        imageUrl={this.props.creative.result}
        onDownload={(imageUrl) => this.startDownload(imageUrl, "download")}
        onShare={(imageUrl) => this.startDownload(imageUrl, "share")}
      />);
    } else {
      this.startDownload(this.props.creative.result, "download");
    }
  };

  handleRetryProcessingClick = () => {
    this.props.creative.markAsPending();
    this.props.creative.removeExtra(Creative.EXTRA_STARTED_AT);

    processingManager.retryCreative(this.props.creative);
  };

  handleImageLoaded = (e) => {
    if (this.props.isOverlayed) {
      return;
    }

    webviewAnalyticsEvent("template_shown", [
      "",
      "result",
      clientStorage.getProcessedPhotosAmount(),
      processingManager.processing.getExtra("batches_amount", 0),
      "",
      this.props.position,
    ], {
      wt_couple_ai: {
        "face": this.props.creative.getExtra("t_face_id"),
        "gender": this.props.creative.getExtra("t_gender"),
        "canvas": this.props.creative.getExtra("t_canvas_id"),
        "mask": this.props.creative.getExtra("t_mask_id"),
        "tpl": this.props.creative.getExtra("t_prompt_id"),
      }
    });

    logEvent(userEvents.CREATIVE_VIEW, {
      group: this.props.creative.group,
      template_id: this.props.creative.templateId,
      position: this.props.position,
    });
  };

  handleImageFailedToLoad = (e) => {
    logEvent(userEvents.CREATIVE_IMAGE_LOAD_ERROR, {
      group: this.props.creative.group,
      template_id: this.props.creative.templateId,
      position: this.props.position,
      image_url: this.props.creative.result,
    });
  };

  render() {
    const classNames = ["item"];
    let content = null;

    if (this.props.isWaiting) {
      content = <React.Fragment />;
    } else if (this.props.creative.isProcessed) {
      classNames.push("item--processed");
      content = <React.Fragment>
        <LazyImage
          src={this.props.creative.result}
          alt=""
          onLoaded={this.handleImageLoaded}
          onFailedToLoad={this.handleImageFailedToLoad}
        />
        {/*<img src={this.props.creative.result} alt="" />*/}
        {/*<button*/}
        {/*  hidden={!this.props.creative.isProcessed}*/}
        {/*  className="btn-download"*/}
        {/*  onClick={this.handleDownloadButtonClick}>*/}
        {/*  <IconDownload />*/}
        {/*</button>*/}
      </React.Fragment>;
    } else if (this.props.creative.isFailed) {
      classNames.push("item--failed");
      content = <div className="item--failed_content">
        <img
          src={assetUrl(`assets/images/icon-broken-heart.png`)}
          alt="" />
        <p dangerouslySetInnerHTML={{__html: i18n.t("error_title")}} />
        <button onClick={this.handleRetryProcessingClick}>{i18n.t("retry")}</button>
      </div>;
    }

    return <div
      className={classNames.join(" ")}
      onTouchStart={this.handleTouchStart}
      onTouchEnd={this.handleTouchEnd}
      onTouchCancel={this.handleTouchEnd}
      onClick={this.handleClick}
      children={content}
    />;
  }
}

CreativeView.contextType = AppContext;
CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
};

// function IconDownload() {
//   return <svg viewBox="0 0 40 40" fill="none">
//     <g filter="url(#5vj6p7ftpa)">
//       <circle cx="20" cy="20" r="20" fill="#F8D34F"/>
//     </g>
//     <path d="M25.834 19.583 20.001 25l-5.834-5.417M20.001 25V12.5M12.5 28.334h15" stroke="#051320" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
//     <defs>
//       <filter id="5vj6p7ftpa" x="-8" y="-8" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
//         <feFlood floodOpacity="0" result="BackgroundImageFix"/>
//         <feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
//         <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_20_2315"/>
//         <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_20_2315" result="shape"/>
//       </filter>
//     </defs>
//   </svg>;
// }