import React from "react";
import i18n from "../i18n";

const LOADING_TEXT_INTERVAL = 3000;

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      "processing_text_1",
      "processing_text_2",
      "processing_text_3",
      "processing_text_4",
      "processing_text_5",
    ];

    this.state = {
      imageIsLoaded: false,
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      textIndex: 0,
    };

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        if (this.props.image) {
          this.image.src = this.props.image;
        }
      });
    }

    if (prevProps.isHidden !== this.props.isHidden) {
      if (this.props.isHidden) {
        clearInterval(this.updateTextIndexTimer);
        this.updateTextIndex();
      } else {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
      }
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  render() {
    return <main className="loader" hidden={this.props.isHidden}>
      <div className="spinner-container">
        <div className="triple-spinner">
          <div className="triple-spinner2" />
        </div>

        {this.props.images && this.props.images.length > 1 && <div className="triple-spinner-avatars">
          <div className="triple-spinner-avatar-1">
            <img src={this.props.images[0]} alt="" />
          </div>
          <div className="triple-spinner-icon">
            <svg viewBox="0 0 34 34" fill="none">
              <g filter="url(#w2ctgzxzva)">
                  <circle cx="17" cy="17" r="16" fill="#051320" fillOpacity=".32"/>
                  <circle cx="17" cy="17" r="16" stroke="#F8D34F"/>
              </g>
              <circle cx="17" cy="17" r="11.345" fill="#F8D34F"/>
              <path d="M21.364 17.907h-3.422v3.457h-1.9v-3.457h-3.405V16.11h3.405v-3.473h1.9v3.473h3.422v1.797z" fill="#051320"/>
              <defs>
                  <filter id="w2ctgzxzva" x="-7.5" y="-7.5" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
                      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_20_1868"/>
                      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_20_1868" result="shape"/>
                  </filter>
              </defs>
            </svg>
          </div>
          <div className="triple-spinner-avatar-2">
            <img src={this.props.images[1]} alt="" />
          </div>
        </div>}

      </div>
      <div className="loader-text-container" hidden={this.props.hideText}>
        {/*<p className="loader-tip" dangerouslySetInnerHTML={{__html: i18n.t("processing_tip", {item: this.state.textIndex + 1, total: this.texts.length})}} />*/}
        <p dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />
      </div>
    </main>;
  }
}
