import configGeneric from "./config-generic";
import Creative from "../Creative";
import groups from "./groups";

export function getProcessingConfig() {
  return configGeneric;
}

export function getProcessingGroups() {
  return getProcessingConfig().getGroups();
}

export function getCreativesConfigs() {
  const clientDiff = parseInt(window.clientConfig.token.substring(window.clientConfig.token.length - 2), 16) / 255;
  const rolloutOffset = window.appConfig.processings.creativesRollout.heatingOffset * 60_000;

  return getProcessingConfig().getCreatives().filter((config) => {
    if (config.hasExtra(Creative.EXTRA_HIDDEN_AFTER_AT)) {
      return Date.now() <= config.getExtra(Creative.EXTRA_HIDDEN_AFTER_AT);
    }

    if (config.hasExtra(Creative.EXTRA_VISIBLE_AFTER_AT)) {
      const afterAt = config.getExtra(Creative.EXTRA_VISIBLE_AFTER_AT);

      if (Date.now() >= afterAt) {
        return true;
      } else if (Date.now() >= (afterAt - (rolloutOffset * clientDiff))) {
        config.setGroup(groups.COMMON);
        return true;
      } else {
        return false;
      }
    }

    return true;
  });
}

export function getCreativesConfigsByGroup(group, gender) {
  return getCreativesConfigs().filter((config) => {
    if (config.group === group) {
      if (gender && config.hasExtra(Creative.EXTRA_ON_GENDER)) {
        return config.getExtra(Creative.EXTRA_ON_GENDER) === gender;
      }

      return true;
    }

    return false;
  });
}

/**
 * @param {string} group
 * @param {string} gender
 * @returns {CreativeConfig}
 */
export function resolveCreativeConfigByGroup(group, gender) {
  const configs = getCreativesConfigsByGroup(group, gender);
  let selectedConfig = configs.find((config) => config.isSelected);
  if (!selectedConfig) {
    const sum = configs.reduce((value, config) => value + config.chanceToSelect, 0);
    if (sum === 0) {
      selectedConfig = configs[0];
    } else {
      let chance = Math.random() * sum;
      for (let i = 0; i < configs.length; i++) {
        chance -= configs[i].chanceToSelect;
        if (chance <= 0) {
          selectedConfig = configs[i];
          break;
        }
      }

      if (!selectedConfig) {
        selectedConfig = configs[0];
      }
    }
  }

  return selectedConfig;
}
