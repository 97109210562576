import React from "react";
import AppContext from "../contexts/AppContext";
import * as api from "../utils/api";
import {fileToJson} from "../utils/webview";
import {assetUrl, debounce, pwAssetUrl} from "../utils/etc";
import ErrorView from "../components/ErrorView";
import {promisifyImage} from "../utils/image";
import {ApiResponseError} from "../utils/api";
import i18n from "../i18n";
import processingManager from "../photolab/ProcessingManager";
import FileChooseButton from "../components/FileChooseButton";
import {photolabTask} from "../photolab/api";
import PhotolabTaskBuilder from "../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../photolab/PhotolabTaskCollageMethod";
import {generatePath} from "react-router";
import routes from "../routes";
import {logEvent, userEvents} from "../utils/log";

const IMAGE_MAX_SIDE_SIZE = 1200;

export default class UploadPage extends React.Component {

  state = {
    error: null,
    fileError: null,
    files: [],
  };

  componentDidMount() {
    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("UploadPage.handleWebviewTabSelected", 300, () => {
          logEvent(userEvents.PAGE_UPLOAD);
        });
      }
    }, true);

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_UPLOAD);
    }

    this.context.setLoaderImages([]);
  }

  createFiles = (files, isUploaded = false) => {
    if (isUploaded) {
      this.handleFilesUploaded(files.map((file) => Object.assign(file, fileToJson(file.raw))));
      return;
    }

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
    ];

    const allFilesIsAllowed = Array.prototype.every.call(files, (file) => {
      return allowedTypes.includes(file.raw.type.toLowerCase());
    });

    if (!allFilesIsAllowed) {
      this.setState({
        error: new ApiResponseError({
          error_code: 415,
          error_message: i18n.t("error__api_code_415"),
        }),
      }, this.context.hideLoader);
      return;
    }

    Promise.all(files.map(this.fileUpload))
      .then((files) => {
        this.handleFilesUploaded(files);
      })
      .catch((error) => {
        this.setState({error}, this.context.hideLoader);
      });
  };

  fileUpload = (file) => {
    return promisifyFileReader(file.raw)
      .then(promisifyImage)
      .then(prepareImage)
      .then((result) => {
        return api.tempImagesUploadFile(result, file.raw.type.replace("image/"));
      })
      .then((result)=> {
        return Object.assign(file, fileToJson(result));
      })
      .catch((error) => {
        file.error = error;
        file.isLoading = false;

        throw error;
      });
  };

  handleFilesSelected = (files) => {
    files = Array.prototype.slice.call(files);
    files = files.map((file) => {
      return {
        raw: file,
        isLoading: true,
      }
    });

    const _files = this.state.files
      .filter((file) => !file.error)
      .slice();

    _files.push(...files);

    if (!window.clientConfig.features.uploadPageV2 || _files.length > 1) {
      this.context.showLoader(false);
    }

    this.setState({
      error: null,
      fileError: null,
      files: _files,
    }, () => {
      this.createFiles(files, window.clientConfig.isWebview);
    });
  };

  handleFilesUploaded = (files) => {
    const filesTasks = files.slice(-2).map((file) => {
      if (this.state.files.findIndex((item) => item.url === file.url && item.id !== file.id) !== -1) {
        const error = new Error(i18n.t("error_message_duplicate"));
        file.error = error;
        file.isLoading = false;

        return Promise.reject(error);
      }

      const taskConfig = new PhotolabTaskBuilder();
      taskConfig.addImage(new PhotolabTaskImageUrl(file.url));
      taskConfig.addMethod(new PhotolabTaskCollageMethod(4021));

      return photolabTask(taskConfig.build())
        .then((taskResult) => {
          const taskConfig = new PhotolabTaskBuilder();
          taskConfig.addImage(new PhotolabTaskImageUrl(file.url));
          taskConfig.addMethod(new PhotolabTaskCollageMethod("gender_classifier"));

          return photolabTask(taskConfig.build());
        }).then((taskResult) => {
          file.genderTaskResult = taskResult.gender;
          file.isLoading = false;
          return file;
        }).catch((error) => {
          file.error = error;
          file.isLoading = false;

          throw error;
        });
    });

    Promise.allSettled(filesTasks).then((results) => {
      const nextState = {
        fileError: results
          .filter((ps) => ps.status === "rejected")
          .map((ps) => ps.reason)
          .first(),
      }

      this.setState(nextState, () => {
        const processedFiles = this.state.files.filter((file) => !file.error);

        if (processedFiles.length >= 2) {
          logEvent(userEvents.PHOTO_SELECTED_2);
          this.startProcessing();
          return;
        } else if (processedFiles.length >= 1) {
          logEvent(userEvents.PHOTO_SELECTED_1);
        }

        this.context.hideLoader();
      });
    });
  };

  startProcessing = () => {
    processingManager.clear();

    this.context.setLoaderImages([
      this.state.files[0].url,
      this.state.files[1].url,
    ], () => {
      this.context.showLoader(true, () => {
        this.props.history.replace({
          pathname: generatePath(routes.PROCESSING),
          state: {files: this.state.files},
        });
      });
    });
  };

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  renderV1 = () => {
    return <React.Fragment>
      <div className="container container-v1">
        <div className="upload-content-container">
          <div className="upload-content">
            <div className="header">
              <button className="btn-back" onClick={this.handleBackButtonClick}>
                <svg viewBox="0 0 25 16" fill="none">
                  <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM1 9h24V7H1v2z" fill="#fff"/>
                </svg>
              </button>
              <p>{i18n.t("upload_title")}</p>
            </div>

            <div className="upload-notice-container">
              <div className="upload-notice">
                <img
                  src={assetUrl(`assets/images/icon-camera.png`)}
                  alt="" />
                <p dangerouslySetInnerHTML={{__html: i18n.t("upload_notice_1")}} />
              </div>
              <div className="upload-notice">
                <img
                  src={assetUrl(`assets/images/icon-face.png`)}
                  alt="" />
                <p dangerouslySetInnerHTML={{__html: i18n.t("upload_notice_2")}} />
              </div>
            </div>

            <p className="upload-text" dangerouslySetInnerHTML={{__html: i18n.t("upload_notice_text")}} />

            <div className="upload-images-container">
              <div className="upload-images">
                <FileChooseButton
                  className="upload-image-container"
                  numPhotos={this.state.files.isEmpty() ? 2 : 1}
                  onFilesSelected={this.handleFilesSelected}>
                  <div className="upload-image">
                    {this.state.files.length > 0 && <img src={this.state.files[0].url} alt="" />}
                  </div>
                  <IconCamera />
                </FileChooseButton>
                <FileChooseButton
                  className="upload-image-container"
                  numPhotos={this.state.files.isEmpty() ? 2 : 1}
                  onFilesSelected={this.handleFilesSelected}>
                  <div className="upload-image">
                    {this.state.files.length > 1 && <img src={this.state.files[1].url} alt="" />}
                  </div>
                  <IconCamera />
                </FileChooseButton>
                {this.state.fileError && <p className="error-text">{this.state.fileError.message}</p>}
              </div>
            </div>
          </div>

          <FileChooseButton
            className="btn-upload-foto"
            numPhotos={this.state.files.isEmpty() ? 2 : 1}
            onFilesSelected={this.handleFilesSelected}
            children={i18n.t("upload_choose_photos_button")} />

          <p className="upload-footer-text" dangerouslySetInnerHTML={{__html: i18n.t("upload_footer")}} />
        </div>
      </div>
    </React.Fragment>;
  }

  renderV2 = () => {
    const firstFile = this.state.files[0];
    const firstFileIsPending = firstFile && firstFile.isLoading;
    const firstFileIsProcessed = firstFile && firstFile.url && !firstFile.isLoading;
    const firstFileIsFailed = firstFile && firstFile.error;

    const secondFile = this.state.files[1];
    const secondFileIsProcessed = secondFile && secondFile.url && !secondFile.isLoading;
    const secondFileIsPending = secondFile && secondFile.isLoading;
    const secondFileIsFailed = secondFile && secondFile.error;

    return <React.Fragment>
      <div className="container container-v2">
        <div className="upload-content-container">
          <div className="upload-content">
            <div className="header">
              <button className="btn-back" onClick={this.handleBackButtonClick}>
                <svg viewBox="0 0 25 16" fill="none">
                  <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM1 9h24V7H1v2z" fill="#fff"/>
                </svg>
              </button>
              <p>{i18n.t("upload_title")}</p>
            </div>

            <div className="upload-content-video">
              <video
                className=""
                playsInline
                autoPlay
                loop
                muted>
                <source type="video/mp4" src={pwAssetUrl(`duet/create_couple_v2.mp4`)} />
              </video>
            </div>

            <div className="upload-notice-container">
              <div className="upload-notice">
                <img
                  src={assetUrl(`assets/images/icon-camera.png`)}
                  alt="" />
                <p dangerouslySetInnerHTML={{__html: i18n.t("upload_notice_1")}} />
              </div>
              <div className="upload-notice">
                <img
                  src={assetUrl(`assets/images/icon-face.png`)}
                  alt="" />
                <p dangerouslySetInnerHTML={{__html: i18n.t("upload_notice_2")}} />
              </div>
            </div>

            <div className="upload-images-container">
              <FileChooseButton
                className={`upload-image-container ${firstFileIsFailed && "upload-image-container-error"}`}
                numPhotos={this.state.files.isEmpty() ? 2 : 1}
                onFilesSelected={this.handleFilesSelected}>
                <div className="upload-image">
                  {firstFileIsProcessed && <img src={firstFile.url} alt="" />}
                  {firstFileIsPending && <div className="upload-image-loading"/>}
                </div>
                {!firstFileIsPending && <div className="upload-image-icon">
                  <IconMale />
                  <p dangerouslySetInnerHTML={{__html: i18n.t("upload_male")}} />
                </div>}
              </FileChooseButton>
              <FileChooseButton
                className={`upload-image-container ${secondFileIsFailed && "upload-image-container-error"}`}
                numPhotos={this.state.files.isEmpty() ? 2 : 1}
                onFilesSelected={this.handleFilesSelected}>
                <div className="upload-image">
                  {secondFileIsProcessed && <img src={secondFile.url} alt="" />}
                </div>
                <div className="upload-image-icon">
                  <IconFemale />
                  <p dangerouslySetInnerHTML={{__html: i18n.t("upload_female")}} />
                </div>
              </FileChooseButton>

              {this.state.fileError && <p className="error-text">{this.state.fileError.message}</p>}
            </div>
          </div>

          <FileChooseButton
            className="btn-upload-foto"
            numPhotos={this.state.files.isEmpty() ? 2 : 1}
            onFilesSelected={this.handleFilesSelected}
            children={i18n.t("upload_choose_photos_button")} />

          <p className="upload-footer-text" dangerouslySetInnerHTML={{__html: i18n.t("upload_footer")}} />
        </div>
      </div>
    </React.Fragment>;
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onButtonClick={() => this.setState({error: null})}
      />;
    }

    if (this.state.fileError) {
      // todo error view
    }

    if (window.clientConfig.features.uploadPageV2) {
      return this.renderV2();
    } else {
      return this.renderV1();
    }
  }
}

UploadPage.contextType = AppContext;

function promisifyFileReader(input) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.onerror = (e) => reject(e.target.error);
    fileReader.readAsDataURL(input);
  });
}

function prepareImage(image) {
  const shouldResize = image.width > IMAGE_MAX_SIDE_SIZE || image.height > IMAGE_MAX_SIDE_SIZE;

  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext("2d");

  if (shouldResize) {
    const ratio = (image.width > image.height)
      ? IMAGE_MAX_SIDE_SIZE / image.width
      : IMAGE_MAX_SIDE_SIZE / image.height;

    canvas.width = Math.round(image.width * ratio);
    canvas.height = Math.round(image.height * ratio);

    // eslint-disable-next-line valid-typeof
    if (typeof ctx.imageSmoothingQuality !== undefined) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      // todo step by step resize to smooth
    }
  } else {
    ctx.drawImage(image, 0, 0);
  }

  return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 100));
}

function IconCamera () {
  return <svg viewBox="0 0 32 31" fill="none">
  <g opacity=".32" clipPath="url(#ku4m7xktaa)">
      <path d="M22.068 17.876a6.079 6.079 0 0 1-3.746 5.612 6.063 6.063 0 0 1-6.613-1.316A6.076 6.076 0 0 1 16 11.802a6.071 6.071 0 0 1 4.289 1.781 6.083 6.083 0 0 1 1.78 4.293zM32 9.25v17.24a4.166 4.166 0 0 1-2.565 3.859 4.154 4.154 0 0 1-1.595.318H4.16a4.155 4.155 0 0 1-3.847-2.58A4.167 4.167 0 0 1 0 26.492V9.25a4.166 4.166 0 0 1 4.16-4.164H7.72V3.643A3.645 3.645 0 0 1 11.361 0h9.278a3.637 3.637 0 0 1 3.368 2.248c.184.442.278.916.278 1.395v1.44h3.555A4.16 4.16 0 0 1 32 9.25zm-6.81 8.626a9.204 9.204 0 0 0-5.67-8.496 9.18 9.18 0 0 0-10.013 1.992A9.2 9.2 0 0 0 16 27.073a9.196 9.196 0 0 0 6.495-2.696 9.214 9.214 0 0 0 2.696-6.5z" fill="#fff"/>
  </g>
  <defs>
      <clipPath id="ku4m7xktaa">
          <path fill="#fff" d="M0 0h32v30.667H0z"/>
      </clipPath>
  </defs>
</svg>;
}

function IconMale () {
  return <svg viewBox="0 0 40 40">
    <g clipPath="url(#n3ub59if8a)">
        <path d="M24.747 26.022c-.117-1.29-.073-2.19-.073-3.368.585-.306 1.63-2.26 1.808-3.91.459-.038 1.183-.486 1.395-2.254.114-.95-.34-1.484-.617-1.652.747-2.248 2.3-9.2-2.87-9.918-.532-.934-1.895-1.407-3.665-1.407-7.083.13-7.938 5.349-6.385 11.325-.276.168-.73.702-.617 1.652.213 1.768.936 2.216 1.395 2.254.176 1.65 1.264 3.604 1.85 3.91 0 1.179.044 2.079-.073 3.368C15.494 29.79 6.037 28.732 5.6 36H36c-.436-7.267-9.852-6.21-11.253-9.978z" fill="#555F67"/>
    </g>
    <defs>
        <clipPath id="n3ub59if8a">
            <path fill="#fff" d="M0 0h40v40H0z"/>
        </clipPath>
    </defs>
  </svg>;
}

function IconFemale () {
  return <svg viewBox="0 0 40 40">
    <g clipPath="url(#v95jkuxfda)">
        <path d="M24.747 26.022c-.021-.23-.037-.598-.049-.98 3.476-.356 5.915-1.19 5.915-2.161-.01-.002-.009-.04-.009-.056-2.598-2.342 2.253-18.983-6.778-18.645-.567-.48-1.56-.906-2.984-.906-12.233.925-6.824 16.64-9.611 19.606h-.006v.007l.002.001c.01.951 2.367 1.77 5.742 2.134-.01.232-.03.52-.074 1C15.494 29.79 6.037 28.732 5.6 36H36c-.436-7.267-9.852-6.21-11.253-9.978z" fill="#555F67"/>
    </g>
    <defs>
        <clipPath id="v95jkuxfda">
            <path fill="#fff" d="M0 0h40v40H0z"/>
        </clipPath>
    </defs>
  </svg>;
}
