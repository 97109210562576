/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

function getGroups() {
  let list = [
    groups.g1,
    groups.g2,
    groups.g3,
    groups.g4,
  ];

  return list;
}

const faceTemplates = [
  {id: 7088, weight: 10},
  {id: 6632, weight: 20},
];

const positionsTemplatesPairs = [
  {canvasId: 7827, maskId: 7830, gender: "man and woman", weight: 100},
  {canvasId: 7913, maskId: 7914, gender: "man and woman", weight: 200},
  {canvasId: 7909, maskId: 7910, gender: "woman and man", weight: 300},
];

const promptTemplates = [
  {id: 8094},
  {id: 8095},
  {id: 8096},
  {id: 8097},
  {id: 8098},
  {id: 8099},
  {id: 8100},
  {id: 8102},
  {id: 8103},
  {id: 8105},
  {id: 8121},
  {id: 8122},
  {id: 8123},
  {id: 8124},
  {id: 8125},
  {id: 8110},
  {id: 8116},
  {id: 8117},
  {id: 8118},
  {id: 8119},
  {id: 8120},
  {id: 8126},
  {id: 8127},
  {id: 8128},
  {id: 8129},
  {id: 8130},
  {id: 8131},
  {id: 8145},
  {id: 8146},
  {id: 8147},
  {id: 8148},
  {id: 8149},
  {id: 8150},
  {id: 8151},
  {id: 8132},
  {id: 8133},
  {id: 8134},
  {id: 8135},
  {id: 8136},
  {id: 8137},
  {id: 8138},
  {id: 8139},
  {id: 8140},
  {id: 8141},
  {id: 8142},
  {id: 8143},
  {id: 8144},
];

function getRandomIndexWithWeight(array) {
  return array.randomIndex();
  // if (array.isEmpty()) {
  //   return -1;
  // }
  //
  // const sum = array.reduce((t, item) => t + item.weight, 0);
  // let r = Math.floor(Math.random() * (sum + 1));
  //
  // return array.findIndex((item) => {
  //   r -= item.weight;
  //   return r <= 0;
  // });
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function() {
    let listOfAll = [];

    const items = faceTemplates.map((preItem) => {
      return {
        ...preItem,
        children: positionsTemplatesPairs.map((positionItem) => {
          return {
            ...positionItem,
            children: promptTemplates.slice(),
          };
        }),
      };
    });

    while (true) {
      const faceIndex = getRandomIndexWithWeight(items)
      if (faceIndex === -1) {
        break;
      }

      const positionIndex = getRandomIndexWithWeight(items[faceIndex].children)
      if (positionIndex === -1) {
        items.splice(faceIndex, 1);
        continue;
      }

      const promptIndex = getRandomIndexWithWeight(items[faceIndex].children[positionIndex].children)
      if (promptIndex === -1) {
        items[faceIndex].children.splice(positionIndex, 1);
        continue;
      }

      const face = items[faceIndex];
      const position = items[faceIndex].children[positionIndex];
      const prompt = items[faceIndex].children[positionIndex].children[promptIndex];

      const combo = new CreativeConfig(
        groups.g1,
        `${face.id}_[${position.canvasId}_${position.maskId}]_${prompt.id}`,
        handlersNames.COMBO
      );

      const isManFirst = position.gender.indexOf("man") === 0;
      const imagesOrder = [
        {src: "#0"},
        {src: "#1"}
      ];

      combo.setExtra("t_prompt_id", prompt.id);
      combo.setExtra("t_face_id", face.id);
      combo.setExtra("t_canvas_id", position.canvasId);
      combo.setExtra("t_mask_id", position.maskId);
      combo.setExtra("t_gender", position.gender);

      combo.setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: face.id, images: [{src: "@0"}], async: true},
        {id: face.id, images: [{src: "@1"}]},
        {
          id: prompt.id,
          images: isManFirst ? imagesOrder : imagesOrder.reverse(),
          templateParams: {
            gender: position.gender,
            canvas_template_name: position.canvasId,
            mask_template_name: position.maskId,
          },
          setAsFile: "raw",
        },
      ]);

      listOfAll.push(combo);

      items[faceIndex].children[positionIndex].children.splice(promptIndex, 1);
    }

    return listOfAll;
  },
};
