import Modal from "../../components/Modal";
import React from "react";
import ImageWatermarkView from "./ImageWatermarkView";
// import WatermarkRemovalModal from "./WatermarkRemovalModal";
import i18n from "../../i18n";
import {tempImagesUploadFile} from "../../utils/api";
import {promisifyImage} from "../../utils/image";
import {transformToDownloadUrl} from "../../utils/creative";
import PropTypes from "prop-types";
import {assetUrl} from "../../utils/etc";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds} from "../../utils/webview";

const watermarks = {
  newprofilepic: {
    url: assetUrl("assets/watermarks/newprofilepic.png"),
    size: 0.4,
  },
  tooncoin: {
    url: assetUrl("assets/watermarks/tooncoin.png"),
    size: 0.2,
  },
  toonme: {
    url: assetUrl("assets/watermarks/toonme.png"),
    size: 0.3,
  },
  photolab: {
    url: assetUrl("assets/watermarks/photolab.png"),
    size: 0.3,
  },
  unitecom: {
    //url: assetUrl("assets/watermarks/unitecom2.png"),
    url: assetUrl("assets/watermarks/yellow2.png"),
    size: 1,
  },
};

export default class WatermarkBuilderModal extends Modal {

  state = {
    isLoading: true,
    watermarkIsRemoved: window.clientConfig.features.hideWatermarkByDefault,
    watermarkIsCaptured: false,
  };

  layoutedMedia = null;
  watermarkData = {x: 0, y: 0};

  constructor(props) {
    super(props);

    this.watermark = watermarks.unitecom;

    /*if (isWebviewApp(webviewAppIds.tooncoin)) {
      this.watermark = watermarks.tooncoin;
    } else if (isWebviewApp(webviewAppIds.toonme, webviewAppIds.toonmeHuawei)) {
      this.watermark = watermarks.toonme;
    } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
      this.watermark = watermarks.newprofilepic;
    } else if (isWebviewApp(
      webviewAppIds.photolabFree,
      webviewAppIds.photolabProHD,
      webviewAppIds.photolabProIos,
      webviewAppIds.photolabHuawei
    )) {
      this.watermark = watermarks.photolab;
    }*/
  }

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      if (!this.state.isLoading) {
        this.dismiss();
      }

      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handleImageWatermarkViewChanged = (data) => {
    if (this.state.watermarkIsRemoved) {
      this.layoutedMedia = {
        url: this.props.imageUrl,
        data: null,
      };

      return;
    }

    this.layoutedMedia = null;
    this.watermarkData = data;
  };

  handleWatermarkRemoveIntent = () => {
    // this.context.pushModal(<WatermarkRemovalModal
    //   key="WatermarkBuilderModal_WatermarkRemovalModal"
    //   onConfirm={this.handleWatermarkRemoveConfirm}
    //   onCancel={this.handleWatermarkRemoveCancel}
    // />);
    this.handleWatermarkRemoveConfirm();
  };

  handleWatermarkRemoveConfirm = () => {
    this.setState({
      watermarkIsRemoved: true,
    }, () => {
      this.layoutedMedia = {
        url: this.props.imageUrl,
        data: null,
      };
    });
  };

  handleWatermarkRemoveCancel = () => {
    // nothing :)
  };

  handleError = (err) => {
    console.error(err);
  };

  handleSaveButtonClick = (postAction) => {
    this.postAction = postAction;

    if (this.state.watermarkIsCaptured) {
      return;
    }

    if (this.layoutedMedia) {
      this.save(this.layoutedMedia);
    } else {
      this.setState({
        isLoading: true,
        loadingText: i18n.t("result_page__preparing_photo")
      }, () => {
        this.layoutImage().then((mediaData) => {
          this.layoutedMedia = mediaData;
          this.save(mediaData);
        }).catch((err) => {
          this.save({url: this.props.imageUrl, data: null});
          this.handleError(err);
        });
      })
    }
  };

  layoutImage = () => new Promise((resolve, reject) => {
    const proxiedImageUrl = new URL(transformToDownloadUrl(this.props.imageUrl));
    proxiedImageUrl.searchParams.append("rc", "" + Date.now());

    Promise.all([
      promisifyImage(proxiedImageUrl.toString(), true),
      promisifyImage(this.watermark.url, true),
    ]).then(([sourceImage, watermarkImage]) => {
      const watermarkHeight = Math.round(watermarkImage.height * (this.watermarkData.width / watermarkImage.width));

      const canvas = document.createElement("canvas");
      canvas.width = sourceImage.width;
      canvas.height = sourceImage.height + watermarkHeight;

      const canvasCtx = canvas.getContext("2d");
      canvasCtx.drawImage(sourceImage, 0, 0);
      canvasCtx.drawImage(
        watermarkImage,
        this.watermarkData.x,
        this.watermarkData.y + watermarkHeight,
        this.watermarkData.width,
        watermarkHeight
      );

      return canvas;
    }).then((canvas) => {
      const extension = this.props.imageUrl
        .split(".")
        .pop()
        .toLowerCase()
        .replace(/jpg/, "jpeg");

      return new Promise((resolve) => canvas.toBlob(resolve, "image/" + extension));
    }).then((blob) => {
      return tempImagesUploadFile(blob, "jpeg");
    }).then((resultUrl) => {
      resolve({url: resultUrl, data: null});
    }).catch(reject);
  });

  save = (media) => {
    if (this.postAction === "download") {
      this.props.onDownload(media.url, !this.state.watermarkIsRemoved);
    } else {
      this.props.onShare(media.url, !this.state.watermarkIsRemoved);
    }

    this.dismiss();
  };

  render() {
    const saveButtonClasses = ["btn btn-violet save-button"];
    // const saveButtonText = window.clientConfig.isWebview ? i18n.t("save_and_share") : i18n.t("download");
    const saveButtonText = i18n.t("download");
    if (this.state.watermarkIsCaptured) {
      saveButtonClasses.push("pretend-delete");
    }

    return <div className="watermark-builder-modal">
      <div className="container">
        <div className="header">
          <button className="btn-back" onClick={() => this.dismiss()}>
            <svg viewBox="0 0 25 16" fill="none">
              <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM1 9h24V7H1v2z" fill="#fff"/>
            </svg>
          </button>
          <h1>{i18n.t("watermark_screen__title")}</h1>
        </div>
      </div>

      <ImageWatermarkView
        isVideo={false}
        isMuted={false}
        imageUrl={this.props.imageUrl}
        watermarkIsShown={!this.state.watermarkIsRemoved}
        watermarkUrl={this.watermark.url}
        watermarkSize={this.watermark.size}
        canWatermarkDrag={window.clientConfig.isPro}
        onLoaded={() => this.setState({isLoading: false})}
        onError={this.handleError}
        onChange={this.handleImageWatermarkViewChanged}
        onRemoveIntent={this.handleWatermarkRemoveIntent}
        onCaptureStart={() => this.setState({watermarkIsCaptured: true})}
        onCaptureEnd={() => this.setState({watermarkIsCaptured: false})}
      />

      <div className="btns-container">
        <button
          className={saveButtonClasses.join(" ")}
          onClick={() => this.handleSaveButtonClick("download")}>
          <span dangerouslySetInnerHTML={{__html: saveButtonText}} />
          <SvgTrashboxIcon />
        </button>
        <button
          hidden={window.clientConfig.isWeb}
          className="btn-share"
          onClick={() => this.handleSaveButtonClick("share")}>
          <SvgShareBtnIcon />
          Share
        </button>
      </div>

      <div className="loader-watermark" hidden={!this.state.isLoading}>
        <div className="pending-loader-container">
            <div className="pending-loader">
              <div className="pending-loader-slider" />
            </div>
          </div>
      </div>
    </div>
  }
}

WatermarkBuilderModal.propType = {
  onDownload: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

function SvgTrashboxIcon() {
  return <svg viewBox="0 0 52 60">
    <path d="M38.01 60H13.99c-2.599 0-4.782-1.998-5.07-4.64L4.325 13.042h43.35L43.08 55.36C42.792 58.002 40.609 60 38.01 60zM31.1 0c1.408 0 2.55 1.169 2.55 2.609h15.3c1.353 0 2.462 1.08 2.545 2.443l.005.165c0 1.44-1.142 2.61-2.55 2.61H3.05C1.697 7.826.588 6.745.505 5.381L.5 5.217c0-1.44 1.142-2.608 2.55-2.608h15.3C18.35 1.169 19.492 0 20.9 0h10.2z" fillRule="nonzero" />
  </svg>;
}

function SvgShareBtnIcon() {
  return <svg viewBox="0 0 68 24" fill="none" >
    <path d="M37.338 9.99a.749.749 0 0 0-.428.975 3 3 0 1 1-1.732-1.725.75.75 0 1 0 .532-1.402 4.5 4.5 0 0 0-4.777 7.387 4.5 4.5 0 0 0 7.584-2.252 4.5 4.5 0 0 0-.204-2.555.75.75 0 0 0-.975-.428zM39.25 7.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5z" fill="#F33AA0"/>
    <path d="M43 6.75A3.75 3.75 0 0 0 39.325 3 57.832 57.832 0 0 0 28.75 3 3.75 3.75 0 0 0 25 6.69a68.911 68.911 0 0 0 0 10.56A3.75 3.75 0 0 0 28.675 21c1.77.165 3.548.255 5.325.255 1.778 0 3.533-.082 5.288-.255A3.75 3.75 0 0 0 43 17.31a65.32 65.32 0 0 0 0-10.56zm-1.5 10.5a2.25 2.25 0 0 1-2.25 2.25h-.075a53.796 53.796 0 0 1-10.425 0 2.25 2.25 0 0 1-2.25-2.31 69.3 69.3 0 0 1 0-10.44 2.248 2.248 0 0 1 2.318-2.25 59.549 59.549 0 0 1 5.175-.24c1.73-.005 3.482.075 5.257.24a2.25 2.25 0 0 1 2.25 2.31 63.845 63.845 0 0 1 0 10.44z" fill="#F33AA0"/>
    <path d="M52.892 16.304c.996-.37 3.41-2.271 2.727-3.855 0 0-2.937-1.162.368-1.69 0 0-.053-5.175 4.039-5.016 4.092.158 4.197 4.858 4.197 4.858s2.78.37.262 2.006c0 0-.21 1.743 2.518 3.169 0 0-.315 1.108-1.731 1.161 0 0 .787.898-1.836.898 0 0-1.364 1.426-3.462 1.214-2.099-.21-1.574-1.267-4.46-1.214" stroke="#F8D34F" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.199 15.444s-2.013 1.536-3.815 1.324c-1.801-.212 6.623 3.126 10.067-1.907 1.324-1.907 1.43-3.868 1.483-4.503 0-.106.053-.265.106-.371l1.007-1.43-1.272.37 1.219-1.695-1.43.636c-.213.106-.478.053-.69-.053-.53-.371-1.695-1.007-3.02-.053-1.06.741-1.324 1.536-1.43 2.066-.053.37-.424.636-.795.53-1.006-.212-2.702-.69-3.92-1.802-.371-.424-1.113-.212-1.113.371 0 .53.212 1.325 1.113 2.384l-1.113-.53s-.212 1.696 2.013 2.702l-1.112.212" stroke="#00C7FF" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>;
}
