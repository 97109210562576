import * as photolabApi from "../api";
import * as api from "../../utils/api";
import {extractTemplateIdFromXml, PhotolabResponseError} from "../api";
import {PhotolabResponseParseError} from "../api";
import {ApiResponseError} from "../../utils/api";
import {resolveCreativeImageFile} from "../../utils/creative";
import "finally-polyfill";

const FETCH_TASKS_INTERVAL = 1000;
const waitTasksPool = [];
setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);

function safePromisify(value) {
  return value ? Promise.resolve(value) : null;
}

export function promisifyCreativeTask(creative, taskName) {
  return safePromisify(creative.getTask(taskName));
}

export function promisifyImage(url, crossOrigin) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    crossOrigin && (image.crossOrigin = "anonymous");
    image.onload = () => resolve(image);
    image.onerror = () => reject(new LoadImageError("Failed to load image " + url));
    image.src = url;
  });
}

function addPhotolabTaskHelper(creative, taskName, taskConfig, withCache = true) {
  if (withCache) {
    const task = promisifyCreativeTask(creative, taskName);
    if (task != null) {
      return task;
    }
  }

  const templateId = extractTemplateIdFromXml(taskConfig);

  return photolabApi.photolabAddTask(taskConfig)
    .then((taskResult) => {
      taskResult.templateId = templateId;
      creative.setTask(taskName, taskResult);

      return taskResult;
    });
}

function waitPhotolabTaskHelper(creative, taskName, taskResult, timeout, interval = 2000) {
  if (taskResult.resultUrl) {
    return Promise.resolve(taskResult);
  } else {
    return photolabApi.photolabWaitTask(taskResult.requestId, timeout, interval)
      .then((result) => {
        result.templateId = taskResult.templateId;

        if (result.resultUrl) {
          result.resultUrl = result.resultUrl.replace("http://", "https://")
        }

        creative.setTask(taskName, result);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  }
}

function fetchTasks() {
  const ids = waitTasksPool
    .filter((t) => !t.result || t.result.status === 0)
    .map((t) => t.id);

  if (ids.length === 0) {
    setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);
    return;
  }

  api.fetchTasks(ids)
    .then((tasks) => {
      tasks.forEach((task) => {
        const taskInPool = waitTasksPool.find((t) => t.id === task.id);
        if (taskInPool) {
          taskInPool.result = task;
        }
      });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      setTimeout(fetchTasks, FETCH_TASKS_INTERVAL);
    });
}

export function photolabTask(creative, taskName, taskConfig, withCache = true, timeout = 1000, interval = 2000) {
  creative.setTaskConfig(taskName, taskConfig);

  return addPhotolabTaskHelper(creative, taskName, taskConfig, withCache)
    .then((taskResult) => waitPhotolabTaskHelper(creative, taskName, taskResult, timeout, interval));
}

export function waitTaskHelper(creative, taskName, taskResult, timeout = 0, interval = 1000) {
  return waitTask(taskResult.id, timeout, interval)
    .then((result) => {
      creative.setTask(taskName, result);
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * @param {Creative} creative
 * @returns {Promise<Creative>}
 */
export function waitCreative(creative) {
  return new Promise((resolve) => {
    if (creative.isFinished) {
      resolve(creative);
    } else {
      setTimeout(() => {
        waitCreative(creative).then(resolve);
      }, 250);
    }
  });
}

/**
 * @param {Creative} creative
 * @returns {Promise<Creative>}
 */
export function waitCreativeOrCancel(creative) {
  return waitCreative(creative)
    .then((creative) => {
      if (creative.isFailed) {
        throw new HandlerCancelError("The creative is failed", creative.error);
      }

      return creative;
    });
}


export function defaultHandlerResolver(creative, resolve) {
  return () => {
    if (creative.isProcessed) {
      const resultImageUrl = resolveCreativeImageFile(creative);
      if (resultImageUrl) {
        new Image().src = resultImageUrl;
      }
    }

    resolve(creative);
  };
}

export function defaultHandlerCatch(creative, reject) {
  return (err) => {
    console.error(err, err.parentError);

    const type = "internal";
    const errorState = {
      type,
      name: err.name,
      code: err.code,
      message: err.message,
    };

    if (err instanceof PhotolabResponseError || err instanceof PhotolabResponseParseError) {
      errorState.type = "photolab";
    } else if (err instanceof ApiResponseError) {
      errorState.type = "api";
    } else if (err instanceof ApiTaskError) {
      errorState.type = "api_task";
    } else if (err instanceof CreativeTimeoutError) {
      errorState.type = "timeout";
    } else if (err instanceof HandlerCancelError) {
      if (err.parentError !== undefined) {
        errorState.type = err.parentError.type;
        errorState.name = err.parentError.name;
        errorState.code = err.parentError.code;
        errorState.message = err.parentError.message;
      } else {
        errorState.type = "handler_cancel";
      }
    } else if (err instanceof LoadImageError) {
      errorState.type = "network";
    }

    creative.markAsFailed(errorState);

    reject(creative);
  };
}

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

export class HandlerCancelError extends Error {
  constructor(message, parentError) {
    super();
    this.name = "HandlerCancelError";
    this.code = -1;
    this.message = message;
    this.parentError = parentError;
  }
}

export class CreativeTimeoutError extends Error {
  constructor(message) {
    super();
    this.name = "CreativeTimeoutError";
    this.code = -1;
    this.message = message;
  }
}

export class LoadImageError extends Error {
  constructor(message) {
    super();
    this.name = "LoadImageError";
    this.code = -1;
    this.message = message;
  }
}

function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  requestsAmount++;

  if (waitTasksPool.findIndex((t) => t.id === taskId) === -1) {
    waitTasksPool.push({id: taskId});
  }

  function _call(resolve, reject) {
    const task = waitTasksPool.find((t) => t.id === taskId);
    let keepWait = true;

    if (task && task.result) {
      task.result.getResultRequestsAmount = requestsAmount;

      if (task.result.status === 1) {
        keepWait = false;
        resolve(task.result);
      } else if (task.result.status === -1) {
        keepWait = false;
        reject(new ApiTaskError(task.result));
      }
    }

    if (keepWait) {
      setTimeout(() => {
        waitTask(taskId, 0, interval).then(resolve).catch(reject);
      }, interval || 1000);
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => _call(resolve, reject), timeout);
  });
}

export function creativeTimeoutPromise(millis) {
  if (millis <= 0) {
    return new Promise(() => {});
  }

  return new Promise((resolve, reject) => {
    let isPlaying = true;
    const isPlayingChangeHandler = () => {
      isPlaying = document.visibilityState === "visible";
    };

    document.addEventListener("visibilitychange", isPlayingChangeHandler);

    let time = 0;
    const timer = setInterval(() => {
      if (isPlaying) {
        time++;
      }

      if (time * 1000 >= millis) {
        document.removeEventListener("visibilitychange", isPlayingChangeHandler);
        clearInterval(timer);
        reject(new CreativeTimeoutError());
      }
    }, 1000);
  });
}